export enum RuleId {
  ChooseStartPlayer = 1,
  Mulligan,
  DrawRule,
  PlacementRule,
  RevealRule,
  ActivationRule,
  EndPhaseRule,
  ForcedExileActionRule,
  HorseOfAvalonActionRule,
  TeleportationActionRule,
  MimicryActionRule,
  SolvePerforations
}
